<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Net Pay Days Report"
      slot="title"
      link="/helpContent/netPayDays"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="form.staffId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <Year label="Year" v-model="form.year" rules="required" />
        </div>
        <div class="col-sm-2">
          <InputMonth label="Month" v-model="form.month" rules="required" />
        </div>
        <div class="col-sm-3">
          <FormButton
            style="margin-top: 5px"
            type="primary"
            @click="search"
            :rounded="true"
          >
            <Icon type="ios-search" />
          </FormButton>
        </div>
      </FormRow>
    </ValidationObserver>
    <FormRow v-if="status==2">
      <div class="col-sm-12">
        <h6 style="margin-bottom: 5px;">{{ message  }}</h6>
      </div>
    </FormRow>
    <FormRow v-if="status!==2">
      <div class="col-sm-2"></div>
      <div class="col-sm-8">
     
        <!-- width:100% -->
        <table border="1" style="border-collapse: collapse" width="600">
          <!--Balance Leave  -->
          <tr>
            <th width="20%" style="text-align:left">Balance Leave</th>
            <td width="25%">CL</td>
            <td width="25%">{{res.balanceCL}}</td>
            <td width="30%"></td>
          </tr>
          <tr>
            <th></th>
            <td>EL</td>
            <td>{{res.balanceEL}}</td>
            <td></td>
          </tr>
          <tr>
            <th style="text-align:left">Absent</th>
            <td>Dates</td>
            <td>{{ res.absentDates }}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Total absent days</td>
            <td>{{ res.totalAbsentDays }}</td>
            <td></td>
          </tr>
          <tr>
            <th style="text-align:left">Late Coming</th>
            <td>Dates</td>
            <td>{{ res.lateCommingDate }}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>DLC(Due to Late Coming)</td>
            <td>{{ res.dueToLateComing }}</td>
            <td></td>
          </tr>
          <tr>
            <th style="text-align:left">Leave</th>
            <td>Approved Leave</td>
            <td>{{ res.approvedLeave }}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Not Approved Leave</td>
            <td>{{ res.notApprovedLeave }}</td>
            <td></td>
          </tr>
          <tr>
            <th style="text-align:left">Net Deduction</th>
            <td>Number of days</td>
            <td>{{ res.netDeduction }}</td>
            <td></td>
          </tr>
          <tr>
            <th style="text-align:left">Carry Forward</th>
            <td>CL</td>
            <td>{{ res.carryForwardCL }}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>EL</td>
            <td>{{ res.carryForwardEL }}</td>
            <td></td>
          </tr>
          <tr>
            <th style="text-align:left">Net Pay Days</th>
            <td>Number of days</td>
            <td>{{ res.netPayDays }}</td>
            <td></td>
          </tr>
        </table>


      </div>
      <div class="col-sm-2"></div>
    </FormRow>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { getAllStaffByHRorManager, getStaffNetPayReport } from "../api";
import Year from "../../components/Year";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    Year,
  },
  data() {
    return {
      form: {
        staffId: null,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      },
      res:{
        balanceCL:null,
        balanceEL:null,
        absentDates:null,
        totalAbsentDays:null,
        lateCommingDate:null,
        dueToLateComing:null,
        approvedLeave:null,
        notApprovedLeave:null,
        netDeduction:null,
        carryForwardCL:null,
        carryForwardEL:null,
        netPayDays:null,

      },
      staffList: [],
      selectedRows: [],
      message: "",
      status:0,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getStaff();
  },
  methods: {
    async getStaff() {
      await getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
        this.form.staffId = this.userData.user_id;
      });

      this.getDataByFilter();
    },
    search() {
      this.getDataByFilter();
    },

    getDataByFilter() {
      setTimeout(() => {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            // this.showLoader();
            const data = {
              staffId: this.form.staffId,
              month: this.form.month,
              year: this.form.year,
            };
            getStaffNetPayReport(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }
        });
      }, 50);
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      if(response.data.length>0){
        this.res.balanceCL=response.data[0].Balance_CL;
        this.res.balanceEL=response.data[0].Balance_EL;
        this.res.absentDates=response.data[0].Absent_dates;
        this.res.totalAbsentDays=response.data[0].Total_absent_days;
        this.res.lateCommingDate=response.data[0].Late_coming_dates;
        this.res.dueToLateComing=response.data[0].leave_due_to_late;
        this.res.approvedLeave=response.data[0].Approved_leave;
        this.res.notApprovedLeave=response.data[0].not_approved_leave;
        this.res.netDeduction=response.data[0].net_deduction;
        this.res.carryForwardCL=response.data[0].carry_forward_CL;
        this.res.carryForwardEL=response.data[0].carry_forward_EL;
        this.res.netPayDays=response.data[0].Net_pay_days;

      this.message = response.data[0].message?response.data[0].message:'';
      this.status = response.data[0].status;
      }

    },
  },
};
</script>
<style >
td{
  height: 25px;
  word-break: break-all;
}
tr, td {
  padding: 0px 5px 0px 3px ;
  
}
</style>
